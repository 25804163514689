<template>
  <div class="card-body text-center custom-text-primary my-5">
    <h2>Our Traction</h2>
    <div class="traction-row">
      <div class="traction-item">
        <div class="counter" data-target="9">0</div>+
        <p>Years of Trust</p>
      </div>
      <div class="traction-item">
        <div class="counter" data-target="10">0</div>+
        <p>Global Exports</p>
      </div>
      <div class="traction-item">
        <div class="counter" data-target="500">0</div>+
        <p>Components</p>
      </div>
      <div class="traction-item">
        <div class="counter" data-target="10000000">0</div>+
        <p>Parts Delivered</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OurTraction',
  mounted() {
    const counters = document.querySelectorAll('.counter');
    counters.forEach(counter => {
      counter.innerText = '0';

      const updateCounter = () => {
        const target = +counter.getAttribute('data-target');
        const current = +counter.innerText;
        const increment = target / 200;

        if (current < target) {
          counter.innerText = `${Math.ceil(current + increment)}`;
          setTimeout(updateCounter, 20);
        } else {
          counter.innerText = target;
        }
      };

      updateCounter();
    });
  }
}
</script>

<style scoped>
.custom-text-primary {
  color: #333; /* Adjust text color as needed */
}

.traction-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */
}

.traction-item {
  text-align: center;
  flex: 1;
  min-width: 200px; /* Ensures items don't get too small */
}

.counter {
  font-size: 2.5em;
  font-weight: bold;
  display: inline-block;
  margin-right: 5px; /* Space between the number and the "+" */
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .counter {
    font-size: 2em; /* Adjust font size for larger tablets and smaller desktops */
  }

  .traction-item p {
    font-size: 1em; /* Adjust paragraph font size */
  }
}

@media (max-width: 768px) {
  .counter {
    font-size: 1.75em; /* Adjust font size for tablets */
  }

  .traction-item p {
    font-size: 0.9em; /* Adjust paragraph font size */
  }
}

@media (max-width: 480px) {
  .counter {
    font-size: 1.5em; /* Further adjust font size for mobile screens */
  }

  .traction-item p {
    font-size: 0.8em; /* Further adjust paragraph font size */
  }

  .traction-row {
    gap: 10px; /* Reduce gap between items */
  }
}
</style>
