<template>
  <div class="facilities-container">
    <div class="card my-3 custom-bg">
      <h2 class="px-3">Career</h2>
      <div class="card-body content-row">
        <img src="https://lgcengg.s3.ap-south-1.amazonaws.com/Edited+Photos+/Edited+Photo+-++(68).jpg" alt="" class="facilities-image">
        
        <p>
          We believe in a simple fact that GREAT PLACES & GREAT THINGS happen by RIGHT PEOPLE AT RIGHT PLACES AT RIGHT TIME, we are constantly on the lookout for that right talent. If you feel you are a notch better than your peers do let us know , we shall look at you and if found you have a matching Chemistry with our DNA and you have that in you to make LGC better than what we are we would eb happy to welcome you to our world where we are CASTING FUTURE. 
          Human capital is the one that makes a difference to the whole micro-structure of an organization, a culture to sustain and thrive it’s the human capital that can contribute significantly. 
          We look forward to build our team with people with great mindset.
          We believe in Diversity and Inclusivity
          Share your profiles to <span class="email-highlight">careers@lgcengg.in</span>.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OverView'
}
</script>

<style scoped>
.custom-bg {
  background-color: #fed404 !important;
}

.facilities-container {
  background-color: #f9f9f9; /* Set your desired background color */
  padding: 20px;
}

.facilities-container h1, .facilities-container h2 {
  color: #333; /* Set your desired text color */
}

.facilities-container p, .facilities-container ul {
  color: #666; /* Set your desired text color */
  margin-bottom: 20px;
}

.facilities-container ul {
  list-style-type: disc;
  padding-left: 20px;
}

.content-row {
  display: flex;
  align-items: center;
  gap: 20px; /* Space between image and text */
  flex-wrap: wrap; /* Allow content to wrap on smaller screens */
}

.facilities-image {
  width: 100%;
  max-width: 500px; /* Set your desired max width */
  height: auto;
  border: 1px solid #ccc; /* Add border if needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow if needed */
}

.email-highlight {
  font-weight: bold;
  color: #0d47a1; /* Blue color */
}

.typewriter p {
  font-size: 1.5em; /* Set the font size */
  font-family: 'Courier New', Courier, monospace; /* Typewriter-like font */
  overflow: hidden; /* Ensures the content is hidden */
  border-right: .15em solid yellow; /* Cursor effect */
  white-space: nowrap; /* Keeps the text on a single line */
  margin: 0 auto; /* Center the text */
  letter-spacing: .15em; /* Space between letters */
  visibility: hidden; /* Hide the text initially */
}

.line {
  visibility: visible;
  animation: typing 3.5s steps(40, end), blink-caret .75s step-end infinite;
  animation-fill-mode: forwards; /* Ensure the final state persists */
}

.line-1 {
  animation-delay: 0s;
}

.line-2 {
  animation-delay: 3.5s;
}

.line-3 {
  animation-delay: 7s;
}

.traction-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  color: #f9f9f9;
}

.traction-item {
  text-align: center;
  flex: 1;
  color: white;
}

.counter {
  font-size: 2em;
  font-weight: bold;
  
  display: inline-block;
  margin-right: 5px; /* Space between the number and the "+" */
}

@keyframes typing {
  from { width: 0; visibility: visible; }
  to { width: 100%; visibility: visible; }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: yellow; }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .content-row {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: flex-start; /* Align items to the start of the container */
  }

  .facilities-image {
    max-width: 100%; /* Ensure the image does not overflow on small screens */
    margin-bottom: 20px; /* Space below image */
  }

  .card-body {
    padding: 10px; /* Reduce padding on smaller screens */
  }

  .facilities-container p {
    font-size: 0.9em; /* Adjust font size for better readability on smaller screens */
  }
}
</style>
