<template>
  <div class="row custom-bg-tertiary my-3">
    <div class="col-md-8">
      <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="https://lgcengg.s3.ap-south-1.amazonaws.com/Edited+Photos+/LGC+Engineering+-+Industry+Shoot+-+Mandya+-+29-3-2024+-+SPPS+-++(471).JPG" alt="Slide 1" class="d-block w-100">
          </div>
          <div class="carousel-item">
            <img src="https://lgcengg.s3.ap-south-1.amazonaws.com/Edited+Photos+/Edited+Photo+-++(22).jpg" alt="Slide 2" class="d-block w-100">
          </div>
          <div class="carousel-item">
            <img src="https://lgcengg.s3.ap-south-1.amazonaws.com/Edited+Photos+/Edited+Photo+-++(26).jpg" alt="Slide 3" class="d-block w-100">
          </div>
          <div class="carousel-item">
            <img src="https://lgcengg.s3.ap-south-1.amazonaws.com/Edited+Photos+/Edited+Photo+-++(27).jpg" alt="Slide 4" class="d-block w-100">
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    <div class="col-md-4 d-flex flex-column justify-content-around">
      <div class="card clickable-card custom-card-bg custom-text-bold my-3">
        <div class="card-body">
          <h5 class="card-title">Impact by LGC</h5>
        </div>
      </div>
      <div class="card clickable-card custom-card-bg custom-text-bold my-3">
        <div class="card-body">
          <h5 class="card-title">Our Facilities</h5>
        </div>
      </div>
      <div class="card clickable-card custom-card-bg custom-text-bold my-3">
        <div class="card-body">
          <h5 class="card-title">Our Investors</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CarouSel'
}
</script>

<style scoped>
.carousel-inner img {
  height: 300px; /* Adjust the height as needed */
  object-fit: cover;
}

.clickable-card {
  cursor: pointer;
}

.clickable-card:hover {
  background-color: var(--custom-bg-primary) !important;
  color: var(--custom-text-primary) !important;
}

.custom-bg-secondary {
  background-color: var(--custom-bg-secondary) !important;
}

.custom-bg-tertiary {
  background-color: var(--custom-bg-tertiary) !important;
}

.custom-card-bg {
  background-color: #999999 !important;
}

.custom-text-bold {
  color: black !important;
  font-weight: bold !important;
}
</style>
