<template>
  <div class="gallery-container">
    <h2>Gallery</h2>
    <p>
      This section contains gallery information.
    </p>
  </div>
</template>

<script>
export default {
  name: 'GalleryPage' // Multi-word name to comply with linting rules
};
</script>

<style scoped>
.gallery-container {
  background-color: #f0f0f0;
  padding: 20px;
  border: 1px solid #ccc;
}

.gallery-container h2 {
  margin-bottom: 10px;
}

.gallery-container p {
  margin-bottom: 20px;
}
</style>
