<template>
  <div class="card m-3 custom-bg-secondary custom-text-secondary">
    <div class="card-body content-row">
      <img src="https://lgcengg.s3.ap-south-1.amazonaws.com/Edited+Photos+/Edited+Photo+-++(24).jpg" alt="Shell Room" class="facilities-image">
      <div class="text-content">
        <h2>Shell Room</h2>
        <p>
          • LGC produces about 10,000 shells per month.<br>
          • Highly engineered and researched chemicals and materials used for the best results for shells.<br>
          • To attain the best quality, we have a temperature & humidity controlled & regulated shell room.<br>
          • Temperature and Humidity Control Environment for optimum results.<br>
          • Automation and robotic-assisted shell room is our next improvement.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShellRoom'
}
</script>

<style scoped>
.custom-bg-secondary {
  background-color: #fed404 !important; /* Apply the #fed404 color to the card background */
  color: #f9f9f9; /* Set text color to ensure readability against the background */
}

.custom-text-secondary {
  color: #f9f9f9; /* Adjust text color as needed */
}

.content-row {
  display: flex;
  align-items: center;
  gap: 20px; /* Space between image and text */
  background-color: #999999; /* Background color for the image section */
  padding: 20px; /* Add padding if needed */
}

/* Image styling */
.facilities-image {
  width: 100%;
  max-width: 500px; /* Set your desired max width */
  height: auto;
  border: 1px solid #ccc; /* Add border if needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow if needed */
}

/* Text content styling */
.text-content {
  flex: 1;
  color: black;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .content-row {
    flex-direction: column; /* Stack image and text vertically on smaller screens */
    text-align: center; /* Center-align text for better readability */
  }

  .facilities-image {
    max-width: 100%; /* Allow the image to take full width on smaller screens */
    margin-bottom: 20px; /* Add space below the image */
  }

  .text-content {
    margin-bottom: 20px; /* Add space below the text content */
  }
}

@media (max-width: 576px) {
  .card-body {
    padding: 10px; /* Reduce padding on very small screens */
  }

  .text-content h2 {
    font-size: 1.25rem; /* Further adjust heading size */
  }

  .text-content p {
    font-size: 0.875rem; /* Further adjust paragraph text size */
  }
}
</style>
