<template>
    <div class="card m-3 custom-bg-secondary custom-text-secondary">
      <div class="card-body content-row">
        <img
          src="https://lgcengg.s3.ap-south-1.amazonaws.com/Edited+Photos+/Edited+Photo+-++(53).jpg"
          alt="Facilities Image"
          class="facilities-image"
        />
        <div class="text-content">
          <h2>Core Competencies</h2>
          <p>
            • LGC has the flexibility to manufacture low volumes (Less than 500 nos per batch to high volumes of 2500 nos per batch)<br>
            • Wide range wrt weight between 10gms to 18 kg can be manufactured.<br>
            • Part size from few mm to 300 mm in length & wall thickness as low as 1.5 mm can be achieved.<br>
            • With experienced Design and Engineering support we have a quick turnaround Product Development 2 weeks to 4 weeks.<br>
            • Good Quality Standards for Casting Soundness and Metallurgy.
          </p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BriefAbout',
  }
  </script>
  
  <style scoped>
  .custom-bg-secondary {
    background-color: #999999 !important; /* Apply the #999999 color to the card background */
    color: #f9f9f9; /* Set text color to ensure readability against the background */
  }
  
  .text-content {
    color: #000; /* Set text color to black for readability */
  }
  
  .card-body {
    padding: 20px; /* Adjust padding as needed */
    display: flex;
    align-items: center; /* Align items vertically centered */
    gap: 20px; /* Space between image and text */
  }
  
  .card-body h2 {
    margin-bottom: 20px; /* Space below the heading */
  }
  
  .card-body p {
    margin-bottom: 20px; /* Space below the paragraph */
  }
  
  .facilities-image {
    max-width: 40%; /* Ensure the image does not exceed the card's width */
    height: auto; /* Maintain the image aspect ratio */
    border-radius: 8px; /* Optional: Add rounded corners to the image */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add shadow to the image */
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .card-body {
      flex-direction: column; /* Stack the image and text vertically on smaller screens */
      text-align: center; /* Center-align text for better readability */
    }
  
    .facilities-image {
      max-width: 100%; /* Allow the image to take full width on smaller screens */
      margin-bottom: 20px; /* Add space below the image */
    }
  
    .card-body h2 {
      font-size: 1.5rem; /* Adjust heading size on smaller screens */
    }
  
    .card-body p {
      font-size: 1rem; /* Adjust paragraph text size on smaller screens */
    }
  }
  
  @media (max-width: 576px) {
    .card-body {
      padding: 10px; /* Reduce padding on very small screens */
    }
  
    .card-body h2 {
      font-size: 1.25rem; /* Further adjust heading size */
    }
  
    .card-body p {
      font-size: 0.875rem; /* Further adjust paragraph text size */
    }
  }
  </style>
  