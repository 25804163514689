<template>
  <div class="custom-bg-primary custom-text-primary">
    <h2 class="text-center mb-4">Industries We Serve</h2>
    <div class="sectors-row">
      <div class="sector-item">
        <i class="bi bi-airplane-fill"></i>
        <p>AEROSPACE</p>
      </div>
      <div class="sector-item">
        <i class="bi bi-tools"></i>
        <p>INDUSTRIAL VALVES</p>
      </div>
      <div class="sector-item">
        <i class="bi bi-droplet-half"></i>
        <p>PUMPS AND IMPELLERS</p>
      </div>
      <div class="sector-item">
        <i class="bi bi-tree"></i>
        <p>AGRICULTURE</p>
      </div>
      <div class="sector-item">
        <i class="bi bi-train-freight-front"></i>
        <p>RAILWAYS</p>
      </div>
      <div class="sector-item">
        <i class="bi bi-shield-shaded"></i>
        <p>DEFENCE</p>
      </div>
      <div class="sector-item">
        <i class="bi bi-wrench-adjustable-circle"></i>
        <p>GENERAL ENGINEERING</p>
      </div>
      <div class="sector-item">
        <i class="bi bi-box"></i>
        <p>TEXTILES</p>
      </div>
      <div class="sector-item">
        <i class="bi bi-steam"></i>
        <p>BOILERS</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectorsListing'
}
</script>

<style scoped>
/* Set background color to #fed404 */
.custom-bg-primary {
  background-color: #fed404 !important;
}

/* Set text color to black and font weight to bold */
.custom-text-primary {
  color: #000000 !important;
  font-weight: bold !important;
}

.sectors-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.sector-item {
  text-align: center;
  flex: 1 1 30%;
  margin-bottom: 20px;
}

.sector-item i {
  font-size: 2em;
  margin-bottom: 10px;
}

.sector-item p {
  margin: 0;
  font-weight: bold; /* Set the words to bold */
}
</style>

<!-- Include Bootstrap Icons CSS in your main.js or index.html -->
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.5.0/font/bootstrap-icons.min.css">
