<template>
  <div class="custom-bg-tertiary px-3">
    <!-- Why LGC? Card -->
    <div class="card my-3 why-lgc-bg">
      <div class="card-body text-black">
        <h2 class="text-align-center">Why LGC?</h2>
        <p>
          At LGC we are not just another component manufacturer through Investment Casting but we are the one with Responsible Sustainable practices and progressive actions for making EARTH a great happy place to live in.
        </p>
        <p>
          Industrialization can be, and will be done with a great heart towards having a better world. We walk the talk as our efforts towards a better today and the best tomorrow are seen through our investments towards such practices as a massive solar rooftop installed for our energy needs. We shall work towards a paperless office to reduce our carbon footprint. We are engaged in CSR activities through partners in these activities.
        </p>
        <p>"Human capital is at the core of our work culture. We would love to hear your new ideas—let's work together to build a better world for ourselves and future generations."</p>
      </div>
    </div>

    <!-- Industry Shoot Card -->
    <div class="card my-3 custom-bg-primary">
      <div class="card-body content-row">
        <img src="https://lgcengg.s3.ap-south-1.amazonaws.com/Edited+Photos+/LGC+Engineering+-+Industry+Shoot+-+Mandya+-+29-3-2024+-+SPPS+-++(699).JPG" alt="Industry Shoot" class="about-image">
        <p class="about-text text-black">
          Intending to carve a NICHE in the manufacturing sector, we began our journey in 2015 in Tubinakere village, Mandya, Karnataka with a humble setup of a few men and machines and have now grown to a strength of 150+ workforce with the capacity to churn out 50 Tons castings per month.
        </p>
      </div>
    </div>

    <!-- Company Overview Card -->
    <div class="card my-3 custom-bg-custom">
      <div class="card-body text-black">
        <p>
          LGC Engineering is an assimilation of technology, methods, innovations, materials science, technical expertise, and an excellent customer service mindset. From conceptualizing to designing to manufacturing complex precision components and sub-assemblies, we cover 360 degrees of the needs of our customers.
        </p>
        <div class="alert custom-bg-tertiary" role="alert">
          We are ISO 9001:2015 and IATF 16949:2016 certified.
        </div>
        <div class="alert custom-bg-tertiary" role="alert">
          We intend to obtain ISO 13485:2016, ISO 14001:2015, AS 9100:2016, and ISO 45001:2018 certifications at the earliest.
        </div>
      </div>
    </div>

    <!-- Vision Card -->
    <div class="card my-3 vision-bg">
      <div class="card-body content-row text-black">
        <div class="vision-text">
          <h2>Vision</h2>
          <p>Be the most sought-after manufacturing partner in component manufacturing via Investment casting to address challenges of the new world. To bring value to our customers and ecosystem through our continual efforts to excel.</p>
        </div>
        <img src="https://lgcengg.s3.ap-south-1.amazonaws.com/Edited+Photos+/Edited+Photo+-++(34).jpg" alt="Vision" class="facilities-image">
      </div>
    </div>

    <!-- Mission Card -->
    <div class="card my-3 custom-bg-custom">
      <div class="card-body content-row text-black">
        <img src="https://lgcengg.s3.ap-south-1.amazonaws.com/Edited+Photos+/Edited+Photo+-++(36).jpg" alt="Mission" class="facilities-image">
        <div class="mission-text">
          <h2>Mission</h2>
          <ul>
            <li>To be technologically advanced & rightly skilled to deliver the right products to customers.</li>
            <li>To be environmentally responsible and conscious of our actions.</li>
            <li>To contribute positively to nation-building.</li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Quality Card -->
    <div class="card my-3 quality-bg">
      <div class="card-body content-row text-black">
        <div class="quality-text">
          <h2>Quality</h2>
          <p>“Quality is a result of consistent right practices” to ensure we deliver quality every time we have put in place robust systems to deliver. Customer satisfaction being the core we have adopted IATF 16949:2016. Our approach towards quality starts from the basics followed by strong planning. We are a data-driven organization and that enables us to be right at every stage. Continuous improvement in our everyday work guides us to make ourselves better every new day.</p>
        </div>
        <img src="https://lgcengg.s3.ap-south-1.amazonaws.com/Edited+Photos+/Edited+Photo+-++(34).jpg" alt="Quality" class="facilities-image">
      </div>
    </div>

    <OurTeam />
  </div>
</template>

<script>
import OurTeam from "../components/OurTeam.vue";

export default {
  name: 'AboutUs',
  components: {
    OurTeam,
  },
};
</script>

<style scoped>
.facilities-image, .about-image {
  width: 100%;
  max-width: 500px;
  height: auto;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.content-row {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.about-text, .vision-text, .mission-text, .quality-text {
  flex: 1;
}

.custom-bg-primary {
  background-color: #007bff;
}

.custom-bg-secondary {
  background-color: #6c757d;
}

.custom-bg-custom {
  background-color: #999999;
}

.vision-bg {
  background-color: #fed404;
}

.quality-bg {
  background-color: #fed404;
}

.why-lgc-bg {
  background-color: #999999; /* Background color for Why LGC? card */
}

.text-white {
  color: white;
}

.text-black {
  color: black;
}

.text-dark {
  color: #343a40;
}

.font-weight-bold {
  font-weight: bold;
}

.text-align-center {
  text-align: center;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .content-row {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: flex-start; /* Align items to the start of the container */
  }

  .facilities-image, .about-image {
    max-width: 100%; /* Ensure images do not overflow on small screens */
    margin-bottom: 20px; /* Space below images */
  }

  .card-body {
    padding: 10px; /* Reduce padding on smaller screens */
  }

  .text-align-center {
    text-align: center;
  }

  .text-black, .text-dark {
    color: black;
  }
}
</style>
