<template>
  <div class="container">
    <div class="dropdown">
      <!-- Additional Information Header -->
      <div class="info-header">
        <h2>MATERIALS</h2>
        <p>
          LGC is expert in producing Stainless Steel, Duplex & Super Duplex Steel, and Exotic Alloys.
          For any other materials to be cast other than those listed below, please contact 
          <a href="mailto:marketing@lgcengg.in">marketing@lgcengg.in</a>.
        </p>
      </div>

      <!-- Dropdown Sections -->
      <div v-for="type in dropdowns" :key="type.key">
        <!-- Dropdown Header -->
        <div class="dropdown-header" @click="toggleDropdown(type.key)">
          <h3>{{ type.name }}</h3>
          <span v-if="isOpen === type.key">▲</span>
          <span v-else>▼</span>
        </div>

        <!-- Dropdown Content -->
        <transition name="fade">
          <div class="dropdown-content" v-show="isOpen === type.key">
            <table>
              <thead>
                <tr>
                  <th v-for="header in type.headers" :key="header">{{ header }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in type.rows" :key="index">
                  <td v-for="cell in row" :key="cell">{{ cell }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropDown',
  data() {
    return {
      isOpen: null, // Track which dropdown is open
      dropdowns: [
        {
          name: 'MARTENSITIC STAINLESS STEEL',
          key: 'martensitic',
          headers: ['Standard', 'Type'],
          rows: [
            ['ASTM A217', 'CA-15'],
            ['', 'CA-40'],
            ['DIN EN 10213', '1.4107'],
            ['', '1.6982'],
            ['', '1.4317']
          ]
        },
        {
          name: 'COBALT BASED ALLOYS',
          key: 'cobalt',
          headers: ['Material'],
          rows: [
            ['Stellite 6'],
            ['Stellite 12'],
            ['Stellite 20']
          ]
        },
        {
          name: 'NICKEL BASE ALLOYS',
          key: 'nickel',
          headers: ['Standard', 'Material', 'Description'],
          rows: [
            ['ASTM A494', 'M30C', 'Monel'],
            ['', 'M35-1', 'Monel'],
            ['', 'M25S', 'Monel'],
            ['', 'N12MV', 'Hast B'],
            ['', 'CU5MCuC', 'Inconel 825'],
            ['', 'CW2M', 'Hast Alloy'],
            ['', 'CW6M', 'Hast Alloy'],
            ['', 'CW6MC', 'Inconel 625'],
            ['', 'CW12MW', 'Hast C'],
            ['', 'CX2MW', 'Hast C22']
          ]
        },
        {
          name: 'DUPLEX STAINLESS STEEL',
          key: 'duplex',
          headers: ['Standard', 'Material'],
          rows: [
            ['ASTM A995 / 890', 'CD4MCuN (1B)'],
            ['', 'CE8MN (2A)'],
            ['', 'CD6MN (3A)'],
            ['', 'CD3MN (4A)'],
            ['', 'CE3MN (5A)'],
            ['', 'CD3MWCuN (6A)'],
            ['DIN EN 10213', '1.447'],
            ['', '1.4517'],
            ['', '1.4417'],
            ['', '1.4469'],
            ['', '1.4362']
          ]
        },
        {
          name: 'CARBON STEEL',
          key: 'steel',
          headers: ['Standard', 'Material'],
          rows: [
            ['ASTM A216', 'WCB'],
            ['', 'WCC'],
            ['', 'WCA'],
            ['DIN EN 10213', '1.0619'],
            ['', '1.0625'],
            ['', '1.1131'],
            ['', '1.622']
          ]
        },
        {
          name: 'ALLOY STEEL',
          key: 'alloy',
          headers: ['Standard', 'Material'],
          rows: [
            ['ASTM A217', 'WC1'],
            ['', 'WC6'],
            ['', 'WC9'],
            ['', 'C5'],
            ['', 'C12'],
            ['', 'C12A'],
            ['ALL “EN” SERIES GRADE STEELS', 'EN8, EN9, EN19, EN24… etc']
          ]
        },
        {
          name: 'LOW ALLOY STEEL',
          key: 'low',
          headers: ['Standard', 'Material'],
          rows: [
            ['ASTM A732', '1A Type IC 1020'],
            ['', '2A Type IC 1030'],
            ['', '3A Type IC-1040-3A'],
            ['', '8Q Type IC 4140'],
            ['', '13Q Type IC 8620'],
            ['', '7Q Type IC 4130'],
            ['ASTM A352', 'LCB'],
            ['', 'LCC'],
            ['', 'LC2'],
            ['', 'LC2-1'],
            ['', 'LC3'],
            ['ASTM A487', 'Gr 2 Class A,B,C'],
            ['', 'Gr 4 Class A,B,C,D,E'],
            ['', 'Gr 6 Class A,B'],
            ['', 'Gr 7 Class A'],
            ['', 'Gr 8 Class A,B,C'],
            ['DIN EN 10213', '1.5422'],
            ['', '1.5419'],
            ['', '1.7357'],
            ['', '1.7379'],
            ['', '1.772'],
            ['', '1.7706']
          ]
        },
        {
          name: 'STAINLESS STEEL',
          key: 'stain',
          headers: ['Standard', 'Material'],
          rows: [
            ['ASTM A351 /ASTM A743', 'CF8'],
            ['', 'CF8M'],
            ['', 'CF8C'],
            ['', 'CF3M'],
            ['', 'CG8M'],
            ['', 'CG3M'],
            ['', 'CF20'],
            ['', 'CN7M'],
            ['', 'CK3MCuN']
          ]
        }
      ]
    };
  },
  methods: {
    toggleDropdown(type) {
      this.isOpen = this.isOpen === type ? null : type;
    },
  },
};
</script>

<style scoped>
.info-header {
  padding: 10px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ccc;
  text-align: center;
}

.info-header h2 {
  margin: 0;
  font-size: 1.7em; /* Adjusted font size */
  font-weight: bold;
  color: #000;
}

.info-header p {
  margin: 10px 0 0;
  color: #666;
  font-size: 0.9em; /* Adjusted font size */
}

.info-header a {
  color: #fed404;
  text-decoration: none;
}

.info-header a:hover {
  text-decoration: underline;
}

.dropdown {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 20px 0;
  width: 100%;
}

.dropdown-header {
  background-color: #fed404;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333;
}

.dropdown-header h3 {
  margin: 0;
  font-size: 1.2em; /* Adjusted font size */
}

.dropdown-content {
  padding: 10px;
  border-top: 1px solid #ccc;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  font-size: 0.875rem; /* Adjusted font size */
}

th {
  background-color: #f2f2f2;
}

/* Transition for fade effect */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .dropdown-header {
    flex-direction: column; /* Stack items vertically on smaller screens */
    text-align: center;
  }

  .dropdown-header h3 {
    font-size: 1.1em; /* Adjusted font size */
  }
  
  .dropdown-content {
    padding: 5px; /* Reduce padding inside dropdown content */
  }

  table {
    font-size: 0.75rem; /* Adjusted font size for smaller screens */
  }
}
</style>
