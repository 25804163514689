<template>
  <div>
    <DropDown />
    <!-- Other content -->
  </div>
</template>

<script>
import DropDown from '@/components/materials/DropDown.vue'; // Ensure this matches the file name exactly

export default {
  name: 'MaterialsView',
  components: {
    DropDown
  },
  // Other component options
};
</script>

<style scoped>
/* Scoped styles for MaterialsView.vue */
</style>
