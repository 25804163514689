<template>
  <div class="custom-bg-tertiary">
  <TopStrip/>
  <Navbar/>
  <router-view />
  <Footer/>
  <ContactForm/>
  </div>
</template>

<script>
import Footer from './components/Footer.vue';
import Navbar from './components/Navbar.vue';
import TopStrip from './components/TopStrip.vue';
import ContactForm from './components/ContactForm.vue';


export default {
  name: 'App',
  components: {
    Navbar,
    TopStrip,
    Footer,
    ContactForm,
    
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

:root {
  --custom-bg-primary: #fed404;
  --custom-bg-secondary: #333333;
  --custom-bg-tertiary: #f0f0f0;
  --custom-text-primary: #000000;
  --custom-text-secondary: #f0f0f0;
  --custom-text-tertiary: #666666;
}

body {
  font-family: 'Montserrat', sans-serif;
  background-color: var(--custom-bg-tertiary); /* Example usage */
  color: var(--custom-text-primary); /* Example usage */
}

/* Custom classes for background colors */
.custom-bg-primary {
  background-color: var(--custom-bg-primary) !important;
}

.custom-bg-secondary {
  background-color: var(--custom-bg-secondary) !important;
}

.custom-bg-tertiary {
  background-color: var(--custom-bg-tertiary) !important;
}

/* Custom classes for text colors */
.custom-text-primary {
  color: var(--custom-text-primary) !important;
}

.custom-text-secondary {
  color: var(--custom-text-secondary) !important;
}

.custom-text-tertiary {
  color: var(--custom-text-tertiary) !important;
}

.custom-navbar-height {
  height: 60px;
}

.navbar-logo {
  height: 100%;
  max-height: 50px;
  border: 1px solid #acacab;
  box-sizing: border-box;
}

.dropdown-menu {
  margin-top: 0;
}

.dropdown-item:hover {
  background-color: var(--custom-bg-primary);
}
</style>
