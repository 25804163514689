<template>
    <div class="certification-container">
      <h2>Investor Information</h2>
      <p>
        This section contain Investor.
      </p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CertificationPage' // Renamed to 'CertificationPage'
  };
  </script>
  
  <style scoped>
  .certification-container {
    background-color: #f0f0f0;
    padding: 20px;
    border: 1px solid #ccc;
  }
  
  .certification-container h2 {
    margin-bottom: 10px;
  }
  
  .certification-container p {
    margin-bottom: 20px;
  }
  </style>
  