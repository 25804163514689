<template>
  <div class="card m-3 custom-bg-secondary custom-text-secondary">
    <div class="card-body content-row">
      <div class="text-content">
        <h2>Wax Room</h2>
        <p>
          Wax Patterns made with 12T to 35T presses for wax patterns. Uses High-Quality Waxes sourced Globally.
          Capacity to Manufacture 300 Wax trees per day.
          Calibrated and regulated liquid wax used with periodic testing of wax quality.
          Well-trained staff for the assembly section and quality control section for defect free patterns and trees.
        </p>
      </div>
      <img src="https://lgcengg.s3.ap-south-1.amazonaws.com/Edited+Photos+/Edited+Photo+-++(19).jpg" alt="Wax Room" class="facilities-image">
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaxRoom'
}
</script>

<style scoped>
.custom-bg-secondary {
  background-color: #fed404 !important; /* Apply the #fed404 color to the card background */
  color: #f9f9f9; /* Set text color to ensure readability against the dark background */
}

.custom-text-secondary {
  color: #f9f9f9; /* Adjust text color as needed */
}

.content-row {
  display: flex;
  align-items: center;
  gap: 20px; /* Space between image and text */
  justify-content: space-between; /* Ensure space between the text and image */
}

.facilities-image {
  width: 100%;
  max-width: 500px; /* Set your desired max width */
  height: auto;
  border: 1px solid #ccc; /* Add border if needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow if needed */
}

.text-content {
  flex: 1;
  color: black;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .content-row {
    flex-direction: column; /* Stack image and text vertically on smaller screens */
    text-align: center; /* Center-align text for better readability */
  }

  .facilities-image {
    max-width: 100%; /* Allow the image to take full width on smaller screens */
    margin-bottom: 20px; /* Add space below the image */
  }

  .text-content {
    margin-bottom: 20px; /* Add space below the text content */
  }
}

@media (max-width: 576px) {
  .card-body {
    padding: 10px; /* Reduce padding on very small screens */
  }

  .text-content h2 {
    font-size: 1.25rem; /* Further adjust heading size */
  }

  .text-content p {
    font-size: 0.875rem; /* Further adjust paragraph text size */
  }
}

.typewriter p {
  font-size: 1.5em; /* Set the font size */
  font-family: 'Courier New', Courier, monospace; /* Typewriter-like font */
  overflow: hidden; /* Ensures the content is hidden */
  border-right: .15em solid yellow; /* Cursor effect */
  white-space: nowrap; /* Keeps the text on a single line */
  margin: 0 auto; /* Center the text */
  letter-spacing: .15em; /* Space between letters */
  visibility: hidden; /* Hide the text initially */
}

.line {
  visibility: visible;
  animation: typing 3.5s steps(40, end), blink-caret .75s step-end infinite;
  animation-fill-mode: forwards; /* Ensure the final state persists */
}

.line-1 {
  animation-delay: 0s;
}

.line-2 {
  animation-delay: 3.5s;
}

.line-3 {
  animation-delay: 7s;
}

.traction-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  color: #f9f9f9;
}

.traction-item {
  text-align: center;
  flex: 1;
  color: white;
}

.counter {
  font-size: 2em;
  font-weight: bold;
  display: inline-block;
  margin-right: 5px; /* Space between the number and the "+" */
}

@keyframes typing {
  from { width: 0; visibility: visible; }
  to { width: 100%; visibility: visible; }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: yellow; }
}
</style>
