<template>
  <div class="brief-about-container">
    <div class="card my-3 custom-bg">
      <div class="card-body content-row">
        <h2>Policies </h2>
        <p>
          This section contains information about various policies and certifications:
        </p>
        
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BriefAbout'
}
</script>

<style scoped>
.brief-about-container {
  background-color: #f9f9f9; /* Set your desired background color */
  padding: 20px;
  text-align: justify;
}

.brief-about-container h2 {
  margin-bottom: 20px;
}

.brief-about-container p {
  margin-bottom: 20px;
}

.brief-about-container .card {
  background-color: #f3cc0a; /* Set your desired card background color */
  color: black;
  border: none;
  padding: 20px;
}

.custom-bg {
  background-color:  #f3cc0a!important; /* Override Bootstrap background color */
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}

/* Additional styles if needed */
.btn-custom {
  background-color: #acacab;
}

.card-body button:hover {
  background-color: #999999;
  color: #f9f9f9; /* Darker shade for hover effect */
}
</style>
