<template>
  <div>
    <CsrContent />
    <QualitySystem />
    <CoreCompetencies/>
    
    <!-- Other content -->
  </div>
</template>

<script>
import CsrContent from '@/components/csr/Csrcontent.vue'; // Corrected import path and component name

import QualitySystem from '@/components/csr/QualitySystem.vue';

import CoreCompetencies from '@/components/csr/CoreCompetencies.vue';



export default {
  name: 'CsrView',
  components: {
    CsrContent,
    QualitySystem,
    CoreCompetencies,
    
  },
  // Other component options
};
</script>

<style scoped>
/* Scoped styles for CsrVier.vue */
</style>
