<template>
  <nav class="navbar navbar-expand-lg navbar-light custom-navbar">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" @click="toggleNavbar">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div :class="{'collapse': !isNavbarOpen, 'navbar-collapse': true}" id="navbarNav">
        <div class="navbar-nav me-auto">
          <router-link class="nav-item nav-link custom-link custom-text-primary" to="/">Home</router-link>
          <router-link class="nav-item nav-link custom-link custom-text-primary" to="/about">About Us</router-link>
          <router-link class="nav-item nav-link custom-link custom-text-primary" to="/certification">Certifications</router-link>
          <router-link class="nav-item nav-link custom-link custom-text-primary" to="/gallery">Gallery</router-link>
          <router-link class="nav-item nav-link custom-link custom-text-primary" to="/careers">Careers</router-link>
          <router-link class="nav-item nav-link custom-link custom-text-primary" to="/investors">Investors</router-link>
        </div>
      </div>
      <div class="d-flex ms-auto">
        <span class="navbar-brand nav-link">marketing@lgcengg.in</span>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'TopStrip',
  data() {
    return {
      isNavbarOpen: false,
    };
  },
  methods: {
    toggleNavbar() {
      this.isNavbarOpen = !this.isNavbarOpen;
    },
  },
}
</script>

<style scoped>
.custom-navbar {
  background-color: #999999 !important; /* Set background color to #999999 and use !important to ensure it applies */
}

.custom-link {
  text-decoration: none; /* Remove underline from links */
  font-weight: bold; /* Make the text bold */
}

.custom-link:hover {
  text-decoration: underline; /* Optional: Add underline on hover */
}

.navbar-nav .nav-link {
  padding: 0.5rem 1rem; /* Adjust padding for links */
}

@media (max-width: 768px) {
  .navbar-nav .nav-link {
    padding: 0.5rem 0.75rem; /* Adjust padding for smaller screens */
  }
}
</style>
