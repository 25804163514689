<template>
  <div class="custom-bg-primary custom-text-primary my-3 p-3 brief-about-container">
    <div class="card-body custom-text-primary typewriter px-2">
      <p class="line line-1">What’s complex for the world, We simplify it!</p>
      <p class="line line-2">That’s LGC Engineering!</p>
      <p class="line line-3">LGC Engineering is a new-age Investment casting Company.</p>
    </div>
    <button class="btn custom-bg-tertiary">Know more</button>
  </div>
</template>

<script>
export default {
  name: 'BriefAbout'
}
</script>

<style scoped>
.brief-about-container {
  padding: 20px;
  text-align: justify;
}

.brief-about-container h2 {
  margin-bottom: 20px;
}

.brief-about-container p {
  margin-bottom: 20px;
}

.brief-about-container button {
  background-color: #fed404; /* Primary button color */
  color: black;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.brief-about-container button:hover {
  background-color: var(--custom-bg-tertiary) !important;
  color: var(--custom-bg-secondary); /* Darker shade for hover effect */
}

/* Typewriter Effect for Larger Screens */
.typewriter p {
  font-size: 1.5em; /* Set the font size */
  overflow: hidden; /* Ensures the content is hidden */
  border-right: .15em solid yellow; /* Cursor effect */
  white-space: nowrap; /* Keeps the text on a single line */
  margin: 0 auto; /* Center the text */
  letter-spacing: .15em; /* Space between letters */
  visibility: hidden; /* Hide the text initially */
  display: block;
  width: 100%; /* Ensure it takes full width */
  box-sizing: border-box; /* Include padding and border in width */
}

.line {
  visibility: visible;
  animation: typing 3.5s steps(40, end), blink-caret .75s step-end infinite;
  animation-fill-mode: forwards; /* Ensure the final state persists */
}

.line-1 {
  animation-delay: 0s;
}

.line-2 {
  animation-delay: 3.5s;
}

.line-3 {
  animation-delay: 7s;
}

.custom-bg {
  background-color: #fed404 !important;
}

@keyframes typing {
  from { width: 0; visibility: visible; }
  to { width: 100%; visibility: visible; }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: yellow; }
}

/* Fade-In Effect for Smaller Screens */
@media (max-width: 768px) {
  .brief-about-container {
    padding: 10px;
    text-align: left;
  }

  .typewriter p {
    font-size: 1.2em;
    white-space: normal; /* Allow wrapping */
    border-right: none; /* Remove cursor effect */
    animation: fadeIn 2s ease-in-out both; /* Apply fade-in animation */
    visibility: visible; /* Ensure text is visible */
  }

  .line {
    visibility: visible;
    animation: none; /* Disable typewriter animation */
  }

  .brief-about-container button {
    padding: 8px 16px;
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .brief-about-container {
    padding: 5px;
    text-align: left;
  }

  .typewriter p {
    font-size: 1em;
  }

  .brief-about-container button {
    padding: 6px 12px;
    font-size: 0.8em;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
