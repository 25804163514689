<template>
  <div class="m-5 custom-bg-tertiary">
    <Carousel />
    <BriefAbout />
    <OurTraction/>
    <SectorsListing />
    
  </div>
</template>

<script>
import BriefAbout from "../components/BriefAbout.vue";
import Carousel from "../components/Carousel.vue";
import SectorsListing from "../components/SectorsListing.vue";
import OurTraction from "../components/OurTraction.vue";
export default {
  name: 'HoMe',
  components: {
    Carousel,
    BriefAbout,
    SectorsListing,
    OurTraction
  }
};
</script>

<style>
/* Your existing styles */
</style>
