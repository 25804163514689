<template>
  <div class="card m-3 custom-bg-secondary custom-text-secondary">
    <div class="card-body content-row">
      <img
        src="https://lgcengg.s3.ap-south-1.amazonaws.com/Edited+Photos+/Edited+Photo+-++(38).jpg"
        alt="Facilities Image"
        class="facilities-image"
      />
      <div class="text-content">
        <h2>CSR Information</h2>
        <p>
          Being socially responsible is not just our duty but it's our responsibility to give back to society for a better future.
          We consistently contribute and dedicate to activities for society.
          <br><br>
          Vision: Be the most sought-after manufacturing partner in component manufacturing via the investment casting route to address challenges of the new world. To bring value to our customers and ecosystem through our continual efforts to excel. Be responsible for a better world in our thoughts and actions.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BriefAbout'
}
</script>

<style scoped>
.custom-bg-secondary {
  background-color: #999999 !important; /* Apply the #999999 color to the card background */
  color: #f9f9f9; /* Set text color to ensure readability against the background */
}

.text-content {
  color: #000; /* Set text color to black for readability */
}

.card-body {
  padding: 20px; /* Adjust padding as needed */
  display: flex;
  align-items: center; /* Align items vertically centered */
  gap: 20px; /* Space between image and text */
}

.card-body h2 {
  margin-bottom: 20px; /* Space below the heading */
}

.card-body p {
  margin-bottom: 20px; /* Space below the paragraph */
}

.facilities-image {
  max-width: 40%; /* Ensure the image does not exceed the card's width */
  height: auto; /* Maintain the image aspect ratio */
  border-radius: 8px; /* Optional: Add rounded corners to the image */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add shadow to the image */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .card-body {
    flex-direction: column; /* Stack the image and text vertically on smaller screens */
    text-align: center; /* Center-align text for better readability */
  }

  .facilities-image {
    max-width: 100%; /* Allow the image to take full width on smaller screens */
    margin-bottom: 20px; /* Add space below the image */
  }

  .card-body h2 {
    font-size: 1.5rem; /* Adjust heading size on smaller screens */
  }

  .card-body p {
    font-size: 1rem; /* Adjust paragraph text size on smaller screens */
  }
}

@media (max-width: 576px) {
  .card-body {
    padding: 10px; /* Reduce padding on very small screens */
  }

  .card-body h2 {
    font-size: 1.25rem; /* Further adjust heading size */
  }

  .card-body p {
    font-size: 0.875rem; /* Further adjust paragraph text size */
  }
}
</style>
