<template>
  <div class="card-body text-center custom-bg-custom custom-text-primary my-5 py-3">
    <h2>{{ teamText }}</h2>
    <img :src="teamImage" alt="Team Image" class="team-image my-3">
  </div>
</template>

<script>
export default {
  name: 'OurTeam',
  data() {
    return {
      teamImage: "https://lgcengg.s3.ap-south-1.amazonaws.com/Edited+Photos+/Edited+Photo+-++(29).jpg", // Replace with the actual image URL or import
      teamText: 'Meet Our Team' // Replace with the actual team text
    };
  }
}
</script>

<style scoped>
.team-image {
  width: 100%;
  max-width: 750px; /* Limit the maximum width of the image */
  height: auto;
  object-fit: cover;
  border-radius: 10%; /* Rounded corners for the image */
  margin: 0 auto; /* Center the image */
}

.custom-bg-custom {
  background-color: #999999; /* Updated background color */
}

.custom-text-primary {
  color: white; /* Assuming this is the text color */
}

@media (max-width: 768px) {
  .team-image {
    max-width: 100%; /* Ensure the image fits within the container */
  }

  .card-body {
    padding: 10px; /* Adjust padding for smaller screens */
  }
}
</style>
