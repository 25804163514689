<template>
  <div>
    <OverView/>
    <WaxRoom/>
    <ShellRoom/>
    <div class="facilities-container">
      <!-- Add images where relevant -->
      <div class="images-section">
        <!--img src="../assets/your-image1.jpg" alt="Image 1" class="facilities-image"-->
        <!--img src="../assets/your-image2.jpg" alt="Image 2" class="facilities-image"-->
      </div>
    </div>
  </div>
</template>

<script>
import OverView from "../components/facilities/Overview.vue";
import WaxRoom from "../components/facilities/WaxRoom.vue";
import ShellRoom from "../components/facilities/ShellRoom.vue";

export default {
  name: 'FacilitiesSection',
  components: {
    OverView,
    WaxRoom,
    ShellRoom
  },
  mounted() {
    const counters = document.querySelectorAll('.counter');
    counters.forEach(counter => {
      counter.innerText = '0';

      const updateCounter = () => {
        const target = +counter.getAttribute('data-target');
        const current = +counter.innerText;
        const increment = target / 200;

        if (current < target) {
          counter.innerText = `${Math.ceil(current + increment)}`;
          setTimeout(updateCounter, 20);
        } else {
          counter.innerText = target;
        }
      };

      updateCounter();
    });
  }
};
</script>

<style scoped>
.custom-bg {
  background-color: #ffeb0d !important;
}

.bg-custom {
  background-color: #acacab !important;
}

.facilities-container {
  background-color: #f9f9f9;
  padding: 20px;
}

.facilities-container h1,
.facilities-container h2 {
  color: #333;
}

.facilities-container p,
.facilities-container ul {
  color: #666;
  margin-bottom: 20px;
}

.facilities-container ul {
  list-style-type: disc;
  padding-left: 20px;
}

.content-row {
  display: flex;
  align-items: center;
  gap: 20px;
}

.images-section {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.facilities-image {
  width: 100%;
  max-width: 500px;
  height: auto;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.typewriter p {
  font-size: 1.5em;
  font-family: 'Courier New', Courier, monospace;
  overflow: hidden;
  border-right: .15em solid yellow;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: .15em;
  visibility: hidden;
}

.line {
  visibility: visible;
  animation: typing 3.5s steps(40, end), blink-caret .75s step-end infinite;
  animation-fill-mode: forwards;
}

.line-1 {
  animation-delay: 0s;
}

.line-2 {
  animation-delay: 3.5s;
}

.line-3 {
  animation-delay: 7s;
}

.traction-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  color: #f9f9f9;
}

.traction-item {
  text-align: center;
  flex: 1;
  color: white;
}

.counter {
  font-size: 2em;
  font-weight: bold;
  display: inline-block;
  margin-right: 5px;
}

@keyframes typing {
  from { width: 0; visibility: visible; }
  to { width: 100%; visibility: visible; }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: yellow; }
}
</style>
