<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <router-link class="navbar-brand d-flex align-items-center" to="/">
      <img src="@/assets/lgc-logo.5988ae3e.svg" alt="Logo" class="navbar-logo">
      <span class="navbar-brand-text">LGC Engineering</span>
    </router-link>
    <button 
      class="navbar-toggler" 
      type="button" 
      :class="{ collapsed: !isExpanded }"
      @click="toggleNavbar"
      :aria-expanded="isExpanded.toString()"
      aria-controls="navbarNavAltMarkup" 
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" :class="{ show: isExpanded }" id="navbarNavAltMarkup">
      <div class="navbar-nav">
        <router-link class="nav-link" to="/facilitiesSection">Facilities</router-link>
        <router-link class="nav-link" to="/csr">CSR</router-link>
        <router-link class="nav-link" to="/materials">Materials</router-link>
        <div class="nav-item dropdown custom-dropdown" :class="{ 'show': isPoliciesDropdownOpen }">
          <a class="nav-link dropdown-toggle" href="#" id="policiesDropdown" role="button" @click="togglePoliciesDropdown" :aria-expanded="isPoliciesDropdownOpen.toString()">
            Policies
          </a>
          <ul class="dropdown-menu" aria-labelledby="policiesDropdown">
            <li><router-link class="dropdown-item" to="/policies">All Policies</router-link></li>
            <li><router-link class="dropdown-item" to="/quality-policy">Quality Policy</router-link></li>
            <li><router-link class="dropdown-item" to="/ethics-policy">Ethics Policy</router-link></li>
            <li><router-link class="dropdown-item" to="/privacy-policy">Privacy Policy</router-link></li>
            <li><router-link class="dropdown-item" to="/posh-policy">POSH Policy</router-link></li>
            <li><router-link class="dropdown-item" to="/diversity-policy">Diversity & Inclusivity Policy</router-link></li>
            <li><router-link class="dropdown-item" to="/human-rights-policy">Human Rights Policy</router-link></li>
            <li><router-link class="dropdown-item" to="/whistle-blower-policy">Whistle Blower Policy</router-link></li>
            <li><router-link class="dropdown-item" to="/esh-policy">ESH Policy</router-link></li>
          </ul>
        </div>
        <div class="nav-item dropdown custom-dropdown" :class="{ 'show': isSectorsDropdownOpen }">
          <a class="nav-link dropdown-toggle" href="#" id="sectorsDropdown" role="button" @click="toggleSectorsDropdown" :aria-expanded="isSectorsDropdownOpen.toString()">
            Industries We Serve
          </a>
          <ul class="dropdown-menu" aria-labelledby="sectorsDropdown">
            <li><router-link class="dropdown-item" to="/aerospace">Aerospace</router-link></li>
            <li><router-link class="dropdown-item" to="/industrial-valves">Industrial Valves</router-link></li>
            <li><router-link class="dropdown-item" to="/pumps-and-impellers">Pumps and Impellers</router-link></li>
            <li><router-link class="dropdown-item" to="/agriculture">Agriculture</router-link></li>
            <li><router-link class="dropdown-item" to="/railways">Railways</router-link></li>
            <li><router-link class="dropdown-item" to="/defence">Defence</router-link></li>
            <li><router-link class="dropdown-item" to="/general-engineering">General Engineering</router-link></li>
            <li><router-link class="dropdown-item" to="/textiles">Textiles</router-link></li>
            <li><router-link class="dropdown-item" to="/boilers">Boilers</router-link></li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      isExpanded: false,
      isPoliciesDropdownOpen: false,
      isSectorsDropdownOpen: false
    };
  },
  methods: {
    toggleNavbar() {
      this.isExpanded = !this.isExpanded;
      if (!this.isExpanded) {
        this.closeAllDropdowns();
      }
    },
    togglePoliciesDropdown() {
      this.isPoliciesDropdownOpen = !this.isPoliciesDropdownOpen;
      if (this.isPoliciesDropdownOpen) {
        this.isSectorsDropdownOpen = false;
      }
    },
    toggleSectorsDropdown() {
      this.isSectorsDropdownOpen = !this.isSectorsDropdownOpen;
      if (this.isSectorsDropdownOpen) {
        this.isPoliciesDropdownOpen = false;
      }
    },
    closeAllDropdowns() {
      this.isPoliciesDropdownOpen = false;
      this.isSectorsDropdownOpen = false;
    }
  }
};
</script>

<style scoped>
/* General styles for the navbar */
.navbar {
  z-index: 1050; /* Ensure the navbar is above other content */
  position: relative; /* Ensures that z-index is respected */
  background-color: #fed404; /* Background color */
}

.navbar-logo {
  height: 50px; /* Adjust the height as needed */
  margin-right: 10px; 
  margin-left: 10px; /* Space between the logo and the toggle button */
}

.navbar-brand-text {
  color: #000; /* Text color beside the logo */
  font-weight: bold;
  font-size: 1.2em; /* Adjust the font size as needed */
}

.navbar-toggler {
  border: none;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0,0,0,0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.custom-dropdown:hover .dropdown-menu {
  display: block;
  background-color: var(--custom-bg-tertiary);
  margin-top: 0; /* Ensure the dropdown menu doesn't have extra top margin */
  max-height: calc(100vh - 120px); /* Adjust according to your navbar height and margin */
  overflow-y: auto; /* Enable scrolling if the dropdown menu exceeds viewport height */
}

@media (max-width: 991.98px) { /* Medium and smaller screens */
  .navbar-nav {
    width: 100%;
    text-align: center;
  }

  .navbar-collapse {
    position: absolute;
    top: 56px; /* Adjust according to your navbar height */
    left: 0;
    width: 100%;
    background-color: #fff; /* Example background color for collapsed menu */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1050; /* Ensure the dropdown is above other content */
  }

  .navbar-nav .nav-item {
    display: block;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #ccc; /* Optional: Add a border between items */
  }

  .navbar-nav .nav-link {
    padding: 10px 0;
    text-decoration: none;
    display: block;
  }

  .navbar-toggler {
    display: block;
    float: right;
  }

  .navbar-toggler:focus {
    outline: none;
  }

  .custom-dropdown .dropdown-menu {
    position: static;
    float: none;
  }

  .custom-dropdown .dropdown-toggle::after {
    margin-left: .255em;
  }

  .custom-dropdown .dropdown-menu {
    background-color: #fed404; /* Background color for dropdown menu */
    box-shadow: none; /* Remove box shadow */
    border: none; /* Remove border */
  }

  .custom-dropdown .dropdown-item {
    color: #000; /* Text color for dropdown items */
    padding: 10px 20px;
  }

  .custom-dropdown .dropdown-item:hover {
    background-color: rgba(0, 0, 0, 0.1); /* Background color on hover */
  }
}
</style>
